<template>
  <div class="mb-5">
    <!-- <div class="px-5 py-3">
      <router-link :to="{ name: 'SearchResults' }" class="font-weight-bold">
        <b-icon icon="arrow-left" scale="1.5" class="mr-2"></b-icon
        >Return</router-link
      >
    </div> -->
    <!-- <b-container
    class="d-flex align-items-center justify-content-center"
  >
    <div
      class="my-sm-5 p-sm-5 text-center border justify-content-between align-items-center"
    >
          </div>
</b-container> -->
    <b-container class="my-5">
      <b-card class="text-center">
        <b-card-body class="pb-5">
          <b-card-title><h1>Thank You</h1></b-card-title>
          <b-card-sub-title
            >Go check your e-mail. We've sent you everything you need to
            contiune, you can view the next steps and a representative will be
            in contact with you shortly.</b-card-sub-title
          >
          <b-card-img :src="listing.l_Image" class="pt-3 w-50"> </b-card-img>
          <b-card-text class="text-left pt-3">
            <b-row>
              <b-col class="col-12 col-md-6">
                <b-list-group class="list-group-flush border-bottom">
                  <b-list-group-item
                    >Trim: {{ findTag(7005) }}</b-list-group-item
                  >
                  <b-list-group-item
                    >Kilometers: {{ listing.l_Kilometer }}</b-list-group-item
                  >
                  <b-list-group-item
                    >Engine: {{ findTag(5004) }}</b-list-group-item
                  >
                  <b-list-group-item
                    >Fuel Type: {{ findTag(5006) }}</b-list-group-item
                  >
                  <b-list-group-item
                    >Transmission: {{ findTag(5015) }}</b-list-group-item
                  >
                </b-list-group>
              </b-col>
              <b-col class="col-12 col-md-6">
                <b-list-group class="list-group-flush border-bottom">
                  <b-list-group-item
                    >Interior Color: {{ interiorColor }}</b-list-group-item
                  >
                  <b-list-group-item
                    >Exterior Color: {{ exteriorColor }}</b-list-group-item
                  >
                  <b-list-group-item
                    >Body Type: {{ findTag(7006) }}</b-list-group-item
                  >
                  <b-list-group-item
                    >Drivetrain: {{ findTag(7007) }}</b-list-group-item
                  >
                  <b-list-group-item
                    >VIN: {{ listing.l_VIN }}</b-list-group-item
                  >
                </b-list-group>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card-body>

        <b-row class="border-top pt-4">
          <b-col>
            <p><b>Reservation Confirmation</b></p>
            <p>{{ fees }} Reservation Payment</p>
          </b-col>
          <b-col>
            <p><b>Reservation #</b></p>
            <p>{{ $store.getters.reservationNumber }}</p>
          </b-col>
          <b-col class="m-auto">
            <b-button variant="primary" pill class="w-75" @click="printWindow()"
              >Print Reservation</b-button
            >
          </b-col>
        </b-row></b-card
      >
    </b-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return { fees: 0 };
  },
  methods: {
    findTag(id) {
      //Finds tags in carDetail by ID (ex: Engine: 5004)
      if (this.carDetail) {
        let found = this.carDetail.find(el => el.ptd_TagId == id);
        if (found) return found.Value;
        else return null;
      } else return null;
    },
    printWindow() {
      window.print();
    },
    async getSummaryFees() {
      try {
        const response = await axios.post("SP/post/sp_BuyerPaymentInfo_Get", {
          CurrencyCode: this.$store.getters.currency
        });
        let value = JSON.parse(response.data.result).DepositAmount;
        this.fees = new Intl.NumberFormat(this.$store.getters.currencyCulture, {
          style: "currency",
          currency: this.$store.getters.currency,
          minimumFractionDigits: value % 1 != 0 ? 2 : 0
        }).format(value);
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async sendReserveID() {
      try {
        const response = await axios.post("Stripe/BuyerDepositPayment", {
          Id: this.$store.getters.reservationNumber
        });
        if (response.data === 0) this.$router.push({ name: "Home" });
      } catch (error) {
        console.log(error.response.data);
      }
    }
  },
  computed: {
    listing() {
      if (this.$store.getters.listing) {
        return this.$store.getters.listing[0];
      } else {
        return "";
      }
    },
    priceData() {
      if (this.listing) {
        return JSON.parse(this.listing.Prices)[0];
      } else return "";
    },
    carData() {
      //Parses the lsiting data
      if (this.listing) {
        return JSON.parse(this.listing.TagCategories);
      } else {
        return "";
      }
    },
    carDetail() {
      //Creates an array of the different tags and values
      if (this.carData) {
        let details = this.carData.flatMap(el => el.Tags);
        return details;
      } else return "";
    },
    searchCountry() {
      if (this.$store.getters.destinationCountry) {
        return this.$store.getters.destinationCountry;
      } else return null;
    },
    vehicleCountry() {
      /* cd_CountryCode: // cd_CountryName: // cd_LanguageCode: */
      if (this.$store.getters.listing) {
        return JSON.parse(this.$store.getters.listing[0].OriginContry);
      } else return null;
    },
    interiorColor() {
      return this.findTag(7200) ? this.findTag(7200) : "null";
    },
    exteriorColor() {
      return this.findTag(7100) ? this.findTag(7100).ColorName : "null";
    }
  },
  beforeMount() {
    this.getSummaryFees();
    this.sendReserveID();
    // document.getElementById("header").style.display = "none";
    // document.getElementById("footer").style.display = "none";
    // document.getElementById("socket").style.display = "none";
  },
  beforeDestroy() {
    // document.getElementById("header").style.display = "block";
    // document.getElementById("footer").style.display = "block";
    // document.getElementById("socket").style.display = "block";
    //reservation
    this.$store.dispatch("setListing", null);
    this.$store.dispatch("reservationKeepData", null);
    this.$store.dispatch("reservationAgent", null);
    this.$store.dispatch("reservationSameAddress", null);
    //address1
    this.$store.dispatch("reservationContactAddress1", null);
    this.$store.dispatch("reservationContactAddress2", null);
    this.$store.dispatch("reservationContactZip", null);
    this.$store.dispatch("reservationContactState", null);
    this.$store.dispatch("reservationContactCity", null);
    //address2
    this.$store.dispatch("reservationOther_ContactFirstName", null);
    this.$store.dispatch("reservationOther_ContactLastName", null);
    this.$store.dispatch("reservationOther_ContactEmail", null);
    this.$store.dispatch("reservationOther_ContactMobile", null);
    this.$store.dispatch("reservationOther_ContactAddress1", null);
    this.$store.dispatch("reservationOther_ContactAddress2", null);
    this.$store.dispatch("reservationOther_ContactZip", null);
    this.$store.dispatch("reservationOther_ContactState", null);
    this.$store.dispatch("reservationOther_ContactCity", null);
  }
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     if (from.name) {
  //       vm.$router.push({ name: "Home" });
  //     }
  //   });
  // }
};
</script>
